import './utils/wdyr'; // <--- first import, important
import { scan } from 'react-scan';
import { createRoot } from 'react-dom/client';
import App from './App';
import '@fontsource/roboto';
import { isDevBuild } from 'utils/env';

if (isDevBuild)
  scan({
    enabled: true, // react-scan helps debugging performance/rendering related issues
  });

const container = document.getElementById('root');

if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(<App />);
