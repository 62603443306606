import React from 'react';
import { Sx } from 'types/Sx';

/**
 * Function used to merge two sx objects. It is important that the isArray check is there,
 * because if an sx object is spread directly using ...sx, it potentially slows down the typescript compilation a lot.
 * See this video for resolving typescript compilation issues: https://www.notion.so/Debugging-7fbac804be6e4c83b850cf533d09d607#b18535ae829e4673b98040e1e3378d2a
 * @param sx First sx object to merge
 * @param sx2 Second sx object to merge
 * @returns A merged sx array that can be sent to any MUI component as props
 */
export const mergeSx = (sx?: Sx, sx2?: Sx) => [
  ...(Array.isArray(sx) ? sx : [sx]),
  ...(Array.isArray(sx2) ? sx2 : [sx2]),
];

/**
 * Type used if you want to pass a component that just has Sx for styling
 */
export type SxComponent = (props: { sx?: Sx }) => React.ReactElement;
export interface WithSx {
  sx?: Sx;
}
