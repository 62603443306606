/**
 * Hook for getting the menu options for the sidebar, based on the user's permissions.
 */

import {
  faMapMarkedAlt,
  faTags,
  faUserGear,
  faObjectsColumn,
  faChartGantt,
  faCalendars,
  faShield,
  faHatWizard,
} from '@fortawesome/pro-regular-svg-icons';
import { AssetType } from 'api/generated/graphql';
import { RootRoutes } from 'enums/rootRoutes';
import { faEvents } from 'icons/customFontAwesome/faEvents';
import { useCurrentUser } from 'providers/CurrentUserProvider';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getAssetBaseIcon } from 'rules/asset/types';
import { FontAwesomeIconWrapper } from '../FontAwesomeIconWrapper';
import { ProjectIcon } from 'icons/ProjectIcon';
import { OrganizationIcon } from 'icons/OrganizationIcon';
import { useRouteAccess } from 'routing/useRouteAccess';
import { faGraphql } from 'icons/faGraphQL';

type MenuOption = {
  icon: ReactElement | undefined;
  title: string;
  disabled?: boolean;
  href: string;
  subOptions?: MenuOption[];
};

type GroupedMenuOption = {
  groupName: string;
  menuOptions: MenuOption[];
};

export const useMenuOptions = (): GroupedMenuOption[] => {
  const { currentUser, isInternal } = useCurrentUser();
  const { t } = useTranslation();
  const hasRouteAccess = useRouteAccess();
  if (!currentUser) return [];

  const allOptions: GroupedMenuOption[] = [
    {
      groupName: t('SIDEBAR.OVERVIEW'),
      menuOptions: [
        {
          title: t('DASHBOARD'),
          icon: <FontAwesomeIconWrapper icon={faObjectsColumn} />,
          href: RootRoutes.HOME,
        },
        {
          title: t('MAP'),
          icon: <FontAwesomeIconWrapper icon={faMapMarkedAlt} />,
          href: RootRoutes.MAP,
        },
        {
          title: t('RESERVATION_other'),
          icon: <FontAwesomeIconWrapper icon={faCalendars} />,
          href: RootRoutes.RESERVATIONS,
        },
        {
          title: t('PROJECT_other'),
          icon: <ProjectIcon />,
          href: RootRoutes.PROJECTS,
        },
        {
          title: t('TIMELINES'),
          icon: <FontAwesomeIconWrapper icon={faChartGantt} />,
          href: RootRoutes.TIMELINE,
        },
      ],
    },
    {
      groupName: t('ASSET_other'),
      menuOptions: [
        {
          title: t('BATTERY_other'),
          icon: <FontAwesomeIconWrapper icon={getAssetBaseIcon(AssetType.Battery)} />,
          href: RootRoutes.BATTERIES,
        },
        {
          title: t('CHARGER_other'),
          icon: <FontAwesomeIconWrapper icon={getAssetBaseIcon(AssetType.Charger)} />,
          href: RootRoutes.CHARGERS,
        },
        {
          title: t('SWITCHBOARD_other'),
          icon: <FontAwesomeIconWrapper icon={getAssetBaseIcon(AssetType.Switchboard)} />,
          href: RootRoutes.SWITCHBOARDS,
        },
        {
          title: t('ENERGY_METER_other'),
          icon: <FontAwesomeIconWrapper icon={getAssetBaseIcon(AssetType.EnergyMeter)} />,
          href: RootRoutes.ENERGY_METERS,
        },
        {
          title: t('GENERIC_other'),
          icon: <FontAwesomeIconWrapper icon={getAssetBaseIcon(AssetType.Generic)} />,
          href: RootRoutes.ASSETS.replace(':type', 'generic'),
        },
      ],
    },
    {
      groupName: t('SIDEBAR.ADMINISTRATIVE'),
      menuOptions: [
        {
          title: t('EVENTS'),
          icon: <FontAwesomeIconWrapper icon={faEvents} />,
          href: RootRoutes.EVENTS,
        },
        {
          title: t('MODELS_AND_VENDORS'),
          icon: <FontAwesomeIconWrapper icon={faTags} />,
          href: RootRoutes.MODELS_AND_VENDORS,
        },
        {
          title: t('ORGANIZATION_other'),
          icon: <OrganizationIcon />,
          href: RootRoutes.ORGANIZATIONS,
        },
        {
          title: t('ROLE_other'),
          icon: <FontAwesomeIconWrapper icon={faShield} />,
          href: RootRoutes.ROLE_ADMIN,
        },
        {
          title: t('ADMIN.USERS'),
          icon: <FontAwesomeIconWrapper icon={faUserGear} />,
          href: RootRoutes.USERS,
        },
        {
          title: t('API_EXPLORER'),
          icon: <FontAwesomeIconWrapper icon={faGraphql} />,
          href: RootRoutes.API_EXPLORER,
        },
        {
          title: t('SYS_ADMIN_TOOLS'),
          icon: <FontAwesomeIconWrapper icon={faHatWizard} />,
          href: RootRoutes.SYS_ADMIN_TOOLS,
        },
      ],
    },
  ];

  // Routes that are not restricted by permissions, but should only show in the menu for internal users
  const internalOnly: string[] = [RootRoutes.API_EXPLORER];

  return allOptions.filter((group) => {
    // Filter out menu options that the user does not have access to
    group.menuOptions = group.menuOptions.filter((option) => {
      if (!hasRouteAccess(option.href)) return false;

      if (internalOnly.includes(option.href)) {
        return isInternal;
      }
      return true;
    });

    // Filter out empty groups
    return group.menuOptions.length > 0;
  });
};
