export enum RootRoutes {
  ASSETS_TIMELINE = '/timelines/assets',
  BATTERIES = '/batteries',
  BATTERY_DETAILS = '/batteries/:id',
  BATTERY_EVENTS = '/batteries/:id/events',
  BATTERY_TIMELINE = '/batteries/:id/timeline',
  CHARGER_DETAILS = '/chargers/:id',
  CHARGER_EVENTS = '/chargers/:id/events',
  CHARGER_TIMELINE = '/chargers/:id/timeline',
  CHARGERS = '/chargers',
  ORGANIZATIONS = '/organizations',
  ORGANIZATION_DETAILS = '/organizations/:id',
  OWN_PROFILE = '/profile',
  ENERGY_METERS = '/energymeters',
  ENERGY_METER_DETAILS = '/energymeters/:id',
  ENERGY_METER_TIMELINE = '/energymeters/:id/timeline',
  EVENTS = '/events',
  FLOW = '/flow',
  ASSETS = '/assets/:type',
  ASSET_DETAILS = '/assets/:type/:id',
  ASSET_TIMELINE = '/assets/:type/:id/timeline',
  HOME = '/',
  MAP = '/map',
  MODELS_AND_VENDORS = '/models-and-vendors',
  NOT_FOUND = '/notfound',
  SWITCHBOARDS = '/switchboards',
  SWITCHBOARD_DETAILS = '/switchboards/:id',
  SWITCHBOARD_TIMELINE = '/switchboards/:id/timeline',
  PROJECT_TAB = '/projects/:id',
  PROJECTS = '/projects',
  PROJECTS_TIMELINE = '/timelines/projects',
  TIMELINE = '/timelines',
  USER_PROFILE = '/users/:id',
  USERS = '/users',
  VENDORS = '/vendors',
  RESERVATIONS = '/reservations',
  RESERVATION_DETAILS = '/reservations/:id',
  RESERVATIONS_TIMELINE = '/timelines/reservations',
  DECOMMISSIONED_ASSETS = '/decommissioned-assets',
  POLICY_ADMIN = '/policy-admin',
  POLICIES = '/policies',
  POLICY_EDITOR = '/policy-editor',
  ROLE_ADMIN = '/role-admin',
  ROLE_ADMIN_TAB = '/role-admin/:tab',
  ROLE_EDIT = '/role-admin/edit',
  API_EXPLORER = '/api-explorer',
  SYS_ADMIN_TOOLS = '/sys-admin-tools',
}
